/**
 * -------------------------------------------------
 * Descriptions
 * -------------------------------------------------
 */
export const desc = {
  typography: {
    // All fontSizes-keys here
    body: 'Basic paragraph text.',
    bodySmall: 'Smaller replacement for body-token',
    bodySmallBold: 'Smaller and bolder replacement for body-token',
    bodyBold:
      'To be used as a bolder replacement for body-token. Used in label.',
    lead: 'Bigger leading text.',
    supplementary: 'Smaller (requiredbylaw etc.).',
    tableSmall: 'Smaller table typography for Huutokaupat-admin pages.',
    button: 'Button typography.',
    buttonSmall: 'Small Button typography.',
    buttonLarge: 'Large Button typography.',
    h1: 'H1.',
    h2: 'H2.',
    h3: 'H3.',
    h4: 'H4.',
    h5: 'H5.',
    h6: 'H6.',
    pill: 'Badge text',
  },
  colors: {
    brand: 'Brand color for links, buttons, etc.',
    brandDark: 'For primary-button hover.',
    brandSecondary: 'Second darker green brand color for special cases.',
    text: 'Text color.',
    textInvert: 'Text color for dark backgrounds.',
    textInvertDisabled: 'Text color for disabled textInvert.',
    subdued: 'Grayed text color (requiredbylaw etc.).',
    info: 'For visuals of information.',
    success: 'For visuals of succesfull information.',
    successDark: 'For success-hero-button hover.',
    danger: 'For visuals of danger information.',
    dangerDark: 'For danger-primary-button hover.',
    warning: 'For visuals of warning information.',
    warningDark: 'For visuals of warning information.',
    disabled: 'Disabled background-color for form elements.',
    inputBorder: 'Border color for input-like elements.',
    lineBorder: 'Visually lighter than inputBorder for decorative separators.',
    background: 'Body background color.',
    backgroundGray: 'TBD',
    backgroundInfo: 'Background color for information elements.',
    backgroundSuccess: 'Background color for successfull information elements.',
    backgroundDanger: 'Background color for danger information elements.',
    backgroundWarning: 'Background color for warning information elements.',
    divider: 'Divider line color',
    // secondary:	'A secondary brand color for alternative styling',
    // highlight:	'A background color for highlighting text',
    // muted:	'A faint color for backgrounds, borders, and accents that do not require high contrast with the background color',
  },
  space:
    'Spacing tokens for margin, padding etc. Let the element sizes be defined responsive by the document flow.',
  breakpoints: {
    sm: '',
    md: '',
    lg: '',
    xl: '',
  },
  borders: {
    none: 'No border.',
    input: 'Input border.',
    activeUnderline: 'Bottom border for active element.',
  },
  borderWidths: {
    none: 'No border.',
    basic: 'Basic.',
    activeUnderline: 'Bottom border for active element.',
  },
  radii: {
    none: 'No radius.',
    subtle: 'For buttons etc.',
    basic: 'For inputs etc.',
    box: 'For shadowed box.',
    pill: 'For badge',
    switch: 'For switch input',
  },
  opacities: {
    none: 'Not visible.',
    basicAlpha:
      'Basic transparency - moight be used at some parts of Chakra components.',
    modalBackground: 'Transparency for modal background',
    disabled: 'For styling disabled elements.',
  },
  shadows: {
    none: 'No shadow.',
    basic: 'Basic text shadow.',
    box: 'For shadowed box.',
    inputOutline: 'Input focus outline.',
    outline: 'Focus outline.',
    outlineSecondary: 'Focus outline for secondary button.',
    outlineHighlight: 'Focus outline for highlight button.',
    outlineDanger: 'Focus outline for danger button.',
    openElementsShadow: 'Opening elements like Menu.',
  },
  styles: {
    input: 'Input height.',
    button: 'Buttons minimum heights.',
    icon: 'Icon sizes.',
    toggletip: 'Toggletip styles',
    modal: 'Modal styles',
  },
};
