import React from 'react';
import cx from 'classnames';
import { Box } from './box';

export const hidePrintStyles = `@media print {
  .forge-hide-print {
    display: 'none',
  }
}
`;

export interface HidePrintProps {
  children: React.ReactNode;
  className?: string;
}

export const HidePrint = ({ children, className }: HidePrintProps) => (
  <Box className={cx('forge-hide-print', className)}>{children}</Box>
);
