import { type ITokens, useTokens } from '../tokens';

export { theme } from './chakra';

/**
 * This is not the recommended way to use Forge tokens!
 * Use Chakra UI's Theme API with style props or css variables instead.
 */
export const useTheme = () => {
  const context = {
    brand: 'huutokaupat',
    forgeTokens: useTokens.huutokaupat,
  };
  return context;
};

export { ITokens };
