import { huutokaupat, type ITokens } from './huutokaupat';

export { type ITokens };

/**
 * Brand Tokens
 */
export const useTokens = {
  huutokaupat,
};
