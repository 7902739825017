import React from 'react';
import { Text as CText } from '@chakra-ui/react';
import cx from 'classnames';
import { HidePrint } from '../../components/primitives/hide-print';
import { BoxProps } from '../../components/primitives/box';
import { useTheme } from '../../theme';
import { TypographyTokens } from '../../tokens/naming';
import { type ITokens } from '../../tokens';
import { cartesianProps } from '../../utils/cartesian-props';

export const textVariants = [
  'default',
  'bold',
  'lead',
  'subdued',
  'requiredbylaw',
  'bodySmallBold',
] as const;

type Variant = (typeof textVariants)[number];
export const selectedTypographyFromTokens = (
  forgeTokens: ITokens,
  typography: TypographyTokens
) => {
  // Make sure that button is handled correctly
  const shouldUseButtonFamily = typography.startsWith('button');
  const {
    fonts: { body },
  } = forgeTokens;
  const selectedFamily = !shouldUseButtonFamily ? body : 'button';
  return {
    fontFamily: selectedFamily,
    fontSize: forgeTokens.fontSizes[typography],
    fontWeight: forgeTokens.fontWeights[typography],
    lineHeight: forgeTokens.lineHeights[typography],
    letterSpacing: forgeTokens.letterSpacings[typography],
  };
};
export function typographyStylesFromTokens<T>(
  forgeTokens: ITokens,
  color?: string
): Record<Variant | 'base' | 'tablesmall' | 'tablesmallbold', Partial<T>> {
  return {
    base: {
      ...selectedTypographyFromTokens(forgeTokens, 'body'),
      margin: 0,
      color:
        color && color in forgeTokens.colors
          ? forgeTokens.colors[color as keyof typeof forgeTokens.colors]
          : color,
      wordBreak: 'break-word',
    } as T,
    default: {
      fontSize: 'inherit',
    } as T,
    subdued: {
      color: 'subdued',
    } as T,
    bold: selectedTypographyFromTokens(forgeTokens, 'bodyBold') as T,
    bodySmallBold: selectedTypographyFromTokens(
      forgeTokens,
      'bodySmallBold'
    ) as T,
    lead: selectedTypographyFromTokens(forgeTokens, 'lead') as T,
    requiredbylaw: {
      ...selectedTypographyFromTokens(forgeTokens, 'supplementary'),
      color: 'subdued',
    } as T,
    tablesmall: selectedTypographyFromTokens(forgeTokens, 'tableSmall') as T,
    tablesmallbold: {
      ...selectedTypographyFromTokens(forgeTokens, 'tableSmall'),
      fontWeight: forgeTokens.fontWeights.bodyBold,
    } as T,
  };
}

export interface TextProps extends Omit<BoxProps, 'variant'> {
  /**
   * Text styles
   */
  variant?: Variant;
  internalVariant?: 'tablesmall';
  /**
   * Hide for printed pages
   */
  hidePrint?: boolean;
}

export const Text = React.forwardRef<HTMLParagraphElement, TextProps>(
  (
    {
      variant: variantProp = 'default',
      internalVariant: internalVariantProp,
      hidePrint = false,
      className,
      ...props
    },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const internalVariant =
      internalVariantProp === 'tablesmall' && variantProp === 'bold'
        ? 'tablesmallbold'
        : internalVariantProp;
    const variant = internalVariant || variantProp;
    const color = props.color ?? 'inherit';
    const base = (
      <CText
        ref={ref}
        {...typographyStylesFromTokens<TextProps>(forgeTokens, color as string)
          .base}
        {...typographyStylesFromTokens(forgeTokens)[variant]}
        {...props}
        className={cx(
          'forge-text',
          'forge-text--reset',
          { 'hide-print': hidePrint },
          className
        )}
      />
    );
    return hidePrint ? <HidePrint>{base}</HidePrint> : base;
  }
);

/**
 * For testing
 */

const components = cartesianProps<TextProps>(
  {
    variant: [...textVariants],
    isTruncated: [true, false],
    hidePrint: [true, false],
    margin: [3, undefined],
    padding: [5, undefined],
    children: ['Testing'],
  },
  Text
);

export const toTesting = <>{components}</>;
