import { desc } from './descriptions';
import { FontPrimitiveSizes, Typography, Breakpoints, Details } from './naming';

/**
 * Forge-DS tokens
 * HUUTOKAUPAT
 */

/**
 * -------------------------------------------------
 * Primitives
 * -------------------------------------------------
 */
/**
 * Color
 */
export const colorPrimitives = {
  black: '#000',
  white: '#fff',
  whitegray: '#F2F2F2',
  gray: '#757575',
  grayDark: '#242424',
  grayLight: '#B5B5B5',
  grayLighter: '#E7E7E7',
  grayLightest: '#F5F5F5',
  red: '#BC2222',
  redDark: '#AB1D1D',
  redLight: '#FCEBEB',
  green: '#318000',
  greenDark: '#256200',
  greenDarker: '#142C10',
  greenLight: '#E9F6E1',
  orange: '#C05621',
  orangeLight: '#C97A13',
  orangeLighter: '#FFF5EA',
  orangeDark: '#B74C16',
  beige: '#D8B880',
  blue: '#216AB2',
  blueLight: '#C9D9D9',
  blueLighter: '#EAF5FF',
};

/**
 * Typography
 */
export const fontSizePrimitives: FontPrimitiveSizes = {
  xSmall: '12px',
  small: '14px',
  medium: '16px',
  mediumish: '16px',
  xMedium: '20px',
  large: '18px',
  xLarge: '20px',
  twoXLarge: '24px',
  threeXLarge: '28px',
  fourXLarge: '36px',
  fiveXLarge: '48px',
  sixXLarge: '64px',
};
const lineHeightPrimitives: FontPrimitiveSizes = {
  xSmall: '16px',
  small: '20px',
  medium: '1.5',
  mediumish: '20px',
  xMedium: '28px',
  large: '24px',
  xLarge: '24px',
  twoXLarge: '28px',
  threeXLarge: '32px',
  fourXLarge: '44px',
  fiveXLarge: '56px',
  sixXLarge: '72px',
};

/**
 * -------------------------------------------------
 * Details
 * -------------------------------------------------
 */
const details: Details = {
  version: '1.0.0',
  desc,
};

/**
 * -------------------------------------------------
 * Tokens
 * -------------------------------------------------
 */
/**
 * Typography
 */
const typography: Typography = {
  fonts: {
    body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    heading: 'Campton, "Helvetica Neue", Helvetica, Arial, sans-serif',
    button: 'Campton, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  fontSizes: {
    body: fontSizePrimitives.medium,
    bodySmall: fontSizePrimitives.small,
    bodySmallBold: fontSizePrimitives.small,
    bodyBold: fontSizePrimitives.medium,
    lead: fontSizePrimitives.xMedium,
    supplementary: fontSizePrimitives.xSmall,
    tableSmall: fontSizePrimitives.small,
    button: fontSizePrimitives.small,
    buttonSmall: fontSizePrimitives.xSmall,
    buttonLarge: fontSizePrimitives.medium,
    h1: fontSizePrimitives.fourXLarge,
    h2: fontSizePrimitives.threeXLarge,
    h3: fontSizePrimitives.twoXLarge,
    h4: fontSizePrimitives.xLarge,
    h5: fontSizePrimitives.large,
    h6: fontSizePrimitives.medium,
    pill: fontSizePrimitives.xSmall,
    responsive: {
      sm: {
        h1: fontSizePrimitives.threeXLarge,
        h2: fontSizePrimitives.twoXLarge,
        h3: fontSizePrimitives.xLarge,
        h4: fontSizePrimitives.large,
        h5: fontSizePrimitives.mediumish,
        h6: fontSizePrimitives.small,
      },
      md: {
        h1: fontSizePrimitives.threeXLarge,
        h2: fontSizePrimitives.twoXLarge,
        h3: fontSizePrimitives.xLarge,
        h4: fontSizePrimitives.large,
        h5: fontSizePrimitives.mediumish,
        h6: fontSizePrimitives.small,
      },
    },
  },
  lineHeights: {
    body: lineHeightPrimitives.medium,
    bodySmall: lineHeightPrimitives.small,
    bodySmallBold: lineHeightPrimitives.small,
    bodyBold: lineHeightPrimitives.medium,
    lead: lineHeightPrimitives.xMedium,
    supplementary: lineHeightPrimitives.xSmall,
    tableSmall: lineHeightPrimitives.small,
    button: lineHeightPrimitives.small,
    buttonSmall: lineHeightPrimitives.xSmall,
    buttonLarge: lineHeightPrimitives.medium,
    h1: lineHeightPrimitives.fourXLarge,
    h2: lineHeightPrimitives.threeXLarge,
    h3: lineHeightPrimitives.twoXLarge,
    h4: lineHeightPrimitives.xLarge,
    h5: lineHeightPrimitives.large,
    h6: lineHeightPrimitives.small,
    pill: lineHeightPrimitives.xSmall,
    responsive: {
      sm: {
        h1: lineHeightPrimitives.threeXLarge,
        h2: lineHeightPrimitives.twoXLarge,
        h3: lineHeightPrimitives.xLarge,
        h4: lineHeightPrimitives.large,
        h5: lineHeightPrimitives.mediumish,
        h6: lineHeightPrimitives.small,
      },
      md: {
        h1: lineHeightPrimitives.threeXLarge,
        h2: lineHeightPrimitives.twoXLarge,
        h3: lineHeightPrimitives.xLarge,
        h4: lineHeightPrimitives.large,
        h5: lineHeightPrimitives.mediumish,
        h6: lineHeightPrimitives.small,
      },
    },
  },
  fontWeights: {
    body: 400,
    bodySmall: 400,
    bodySmallBold: 700,
    bodyBold: 700,
    lead: 400,
    supplementary: 400,
    tableSmall: 400,
    button: 500,
    buttonSmall: 500,
    buttonLarge: 500,
    h1: 600,
    h2: 600,
    h3: 600,
    h4: 600,
    h5: 600,
    h6: 600,
    pill: 500,
    responsive: {},
  },
  letterSpacings: {
    body: 'normal',
    bodySmall: 'normal',
    bodySmallBold: 'normal',
    bodyBold: 'normal',
    lead: 'normal',
    supplementary: 'normal',
    tableSmall: 'normal',
    button: 'normal',
    buttonSmall: 'normal',
    buttonLarge: 'normal',
    h1: 'normal',
    h2: 'normal',
    h3: 'normal',
    h4: 'normal',
    h5: 'normal',
    h6: 'normal',
    pill: 'normal',
    responsive: {},
  },
};
/**
 * Semantic Colors
 */
const colors = {
  brand: colorPrimitives.orange,
  brandDark: colorPrimitives.orangeDark,
  brandSecondary: colorPrimitives.greenDarker,
  text: colorPrimitives.grayDark,
  textInvert: colorPrimitives.white,
  textInvertDisabled: colorPrimitives.grayLighter,
  subdued: colorPrimitives.gray,
  info: colorPrimitives.blue,
  success: colorPrimitives.green,
  successDark: colorPrimitives.greenDark,
  danger: colorPrimitives.red,
  dangerDark: colorPrimitives.redDark,
  warning: colorPrimitives.orange,
  warningDark: colorPrimitives.orangeDark,
  disabled: colorPrimitives.grayLightest,
  inputBorder: colorPrimitives.grayLight,
  lineBorder: colorPrimitives.grayLighter,
  background: colorPrimitives.white,
  backgroundGray: colorPrimitives.grayLightest,
  backgroundInfo: colorPrimitives.blueLighter,
  backgroundSuccess: colorPrimitives.greenLight,
  backgroundDanger: colorPrimitives.redLight,
  backgroundWarning: colorPrimitives.orangeLighter,
  divider: colorPrimitives.grayLighter,
};
/**
 * Spacing
 */
const space = ['0px', '4px', '8px', '16px', '24px', '32px', '64px'];
/**
 * CSS-specific
 */
const breakpoints: Breakpoints = {
  sm: '480px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};
const borderWidths = {
  none: '0',
  basic: '1px',
  activeUnderline: '2px',
};
const borders = {
  none: '0',
  input: `${borderWidths.basic} solid ${colors.inputBorder}`,
  activeUnderline: `${borderWidths.activeUnderline} solid ${colors.brand}`,
};
const radii = {
  none: '0',
  subtle: '2px',
  basic: '4px',
  box: '8px',
  pill: '16px',
  switch: '10px',
};
const opacities = {
  none: 0,
  basicAlpha: 0.8,
  modalBackground: 0.5,
  disabled: 0.4,
};
const shadows = {
  none: '0',
  basic: '0 1px 2px rgba(0, 0, 0, 0.2)',
  box: '0 1px 6px 0 rgba(0, 0, 0, 0.2)',
  inputOutline: `0 0 0 1px ${colorPrimitives.green}`,
  outline: 'rgb(192, 86, 33, 0.6) 0px 0px 0px 3px', // colorPrimitives.orange + opacity: 0.6
  outlineSecondary: 'rgb(20, 44, 16, 0.6) 0px 0px 0px 3px', // colorPrimitives.greenDarker + opacity: 0.6
  outlineHighlight: 'rgb(49, 128, 0, 0.6) 0px 0px 0px 3px', // colorPrimitives.green + opacity: 0.6
  outlineDanger: 'rgb(188, 34, 34, 0.6) 0px 0px 0px 3px', // colorPrimitives.red + opacity: 0.6
  openElementsShadow:
    '0px 3px 6px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.12)',
};
const styles = {
  input: { base: { height: '40px', paddingX: 3 } },
  button: {
    mintouch: { minHeight: '40px', minWidth: '40px' },
    default: { minHeight: '40px', paddingY: 2 },
    'default-hero': { minHeight: '48px', paddingY: '12px' },
    'default-lists': { minHeight: '24px', paddingY: '2px' },
    primary: { minHeight: '40px', paddingY: 2 },
    'primary-hero': { minHeight: '48px', paddingY: '12px' },
    'primary-lists': { minHeight: '24px', paddingY: '2px' },
    secondary: { minHeight: '40px', paddingY: 2 },
    'secondary-hero': { minHeight: '48px', paddingY: '12px' },
    'secondary-lists': { minHeight: '24px', paddingY: '2px' },
    highlight: { minHeight: '40px', paddingY: 2 },
    'highlight-hero': { minHeight: '48px', paddingY: '12px' },
    'highlight-lists': { minHeight: '24px', paddingY: '2px' },
    danger: { minHeight: '40px', paddingY: 2 },
    'danger-primary': { minHeight: '40px', paddingY: 2 },
    'danger-hero': { minHeight: '48px', paddingY: '12px' },
    'danger-lists': { minHeight: '24px', paddingY: '2px' },
    'danger-primary-hero': { minHeight: '48px', paddingY: '12px' },
    'danger-primary-lists': { minHeight: '24px', paddingY: '2px' },
  },
  icon: {
    default: { size: '24px' },
    large: { size: '32px' },
    small: { size: '16px' },
  },
  toggletip: { base: { maxWidth: '300px' } },
  modal: { secondary: { maxWidth: '600px' } }, // Is this actually needed anymore?
};

/**
 * -------------------------------------------------
 * Construct tokens
 * Not to be changed below this!
 * -------------------------------------------------
 */
export const huutokaupat = {
  ...details,
  ...typography,
  colors,
  space,
  breakpoints,
  borders,
  borderWidths,
  radii,
  opacities,
  shadows,
  styles,
};
export type ITokens = typeof huutokaupat;
